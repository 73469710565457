import { Component,ViewChild } from '@angular/core';
import { MasterService } from '../../../services/master.service';
import { ChartComponent } from "ng-apexcharts";
import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ApexPlotOptions,
  ApexGrid,
  ApexDataLabels,
  ApexYAxis,
  ApexTitleSubtitle,
  ApexXAxis,
  ApexFill,
  ApexAxisChartSeries,
  ApexTooltip,
  ApexLegend,
  ApexStroke
} from "ng-apexcharts";
import addYears from 'date-fns/addYears';
import { SidebarComponent } from 'src/@vex/components/sidebar/sidebar.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LoginService } from '../../../services/login.service'
import {APICODE} from '../../../utils/enums/api-type.enum';
import { localStorageKeys } from 'src/app/utils/enums/localStorage-keys';
export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
};
export type countOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  plotOptions: ApexPlotOptions;
  grid: ApexGrid,
  labels:any;
  dataLabels:ApexDataLabels;
  legend:ApexLegend;
  color:string[]
};
export type voterDetails ={
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  dataLabels:ApexDataLabels;
  plotOptions: ApexPlotOptions;
  grid: ApexGrid,
  labels:any;
  legend:ApexLegend;
  colors:string[]
}
export type PartyChart = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
   dataLabels: ApexDataLabels;
   legend:ApexLegend;
};
export type ageOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  title: ApexTitleSubtitle;
  colors:string[]
};

@Component({
  selector: 'vex-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  @ViewChild('configpanel', { static: true }) configpanel: SidebarComponent;
  @ViewChild("votersDetailschart") votersDetailschart: ChartComponent;
  @ViewChild("chart") chart: ChartComponent;
  @ViewChild("totalCount") totalCount: ChartComponent;
  @ViewChild("AgeCount") AgeCount: ChartComponent;
  @ViewChild("GenderCount") GenderCount: ChartComponent;
  @ViewChild("partyMemberCount") partyMemberCount: ChartComponent;
  @ViewChild("favourPartyCount") favourPartyCount: ChartComponent;
  public filterform!: FormGroup;
  public chartOptions: Partial<ChartOptions>;
  public religionOptions: Partial<countOptions>;
  public voterDetails: Partial<voterDetails>;
  public AgeDetails: Partial<ageOptions>;
  public GenderDetails: Partial<countOptions>;
  public partyMemberDetails: Partial<PartyChart>;
  public favourPartyDetails: Partial<ageOptions>;
  isActive = false;
  loaderVisbility  = false;
  boothVisible:boolean=true;
  genderDataBind:any;
  voterStatusDetails:any;
  religionStatusDetails:any;
  favourStatusDetails:any;
  ageRangeDetails:any;
  totalVoter:any;
  genderTotalVoter:any;
  religionTotalVoter:any;
  partyTotalVoter:any;
  partyMembershipArray:any[]=[]
  totalFamily:any;
  partyNameData:any[]=[];
  partyCountData:any[]=[];
  ageRangeWise:any[]=[];
  dyanimcAgeRange:any[]=[]
  partyDetailsRange:any[]=[];
  ageNameData:any[]=[];
  partyNames:any;
  partyCounts:any;
  partyDetails:any;
  genderColors:any[]=[];
  voterColors:any[]=[];
  religionColors:any[]=[];
  partyMemberColor:any[]=[];
  appliedFiltersForm: any[] =[];

  partyMemberNoRecord:boolean=false;
  partyNoRecordFound:boolean=false;
  constructor( private masterService: MasterService,private loginService: LoginService,private formBuilder: FormBuilder) {
    this.filterform = this.formBuilder.group({        //Form Builder
      constituencyID:[''],
      booth:[''],
      zoneCode: [''],
      ward: [''],
      wardList: [null]
    }); 
  }
  ngOnInit() {
    // this.masterService.titleName$.next("");
    this.voterStatusBind();
    this.genderBind();
    this.religionBind();
    this.favourPartyBind();
    this.ageDataBinding();
    this.partyMemberBinding();
    this.genderColors = ['#90CAF9', '#F48FB1', '#FFE082'];
    this.voterColors = ['#C5E1A5',"#B0BEC5",'#FFE082'];//['#4DB6AC',"#B0BEC5",'#FFAA33'];
    this.religionColors =['#FFE082','#90CAF9',"#C5E1A5","#B0BEC5"];
    this.partyMemberColor = ['#FFE082','#90CAF9',"#C5E1A5","#B0BEC5",'#F48FB1'];
  }
  openFilterPop($event:any) {
    this.configpanel.open()
  }
  // ConstitutuionIdPassing(id: any) {
  //   this.filterform.patchValue({        //Form Builder
  //     stateID: id.value.stateID,
  //     constituencyID: id.value.constituencyID,
  //     zoneCode: id.value.ZoneID ? id.value.ZoneID : 0,
  //     booth: id.value.booth ? id.value.booth : [],
  //     ward: id.value.ward ? id.value.ward : [],
  //     wardList: id.value.wardList || []
  //   });
  //   console.log("ConstitutuionIdPassing");
  //   this.fnBindDashboardData();
  //   let title = id.value.zonename ? id.value.constituency + " -> " +id.value.zonename : id.value.constituency;
  //   this.masterService.titleName$.next(title+ " -> Dashboard"); 
  // }
  closeFilterPop($event:any) {
    this.configpanel.close()
  }
  dashboardFilter(id:any){
    this.filterform.patchValue({        //Form Builder
      stateID: id.value.stateID,
      constituencyID: id.value.constituencyID,
      zoneCode: id.value.ZoneID ? id.value.ZoneID : 0,
      booth: id.value.booth ? id.value.booth : [],
      ward: id.value.ward ? id.value.ward : [],
      wardList: id.value.wardList || []
    });
    console.log("ConstitutuionIdPassing");
    this.fnBindDashboardData();
    let title = id.value.zonename ? id.value.constituency + " -> " +id.value.zonename : id.value.constituency;
    this.masterService.titleName$.next(title+ " -> Dashboard");
  }
  fnBindDashboardData(){
    this.loaderVisbility  = true;
  var data = {
    "constituencyNumber":this.filterform.value.constituencyID ? this.filterform.value.constituencyID : 1,
     "zoneCode":Number(this.filterform.value.zoneCode) || 0,
     "wardCode": this.filterform.value.ward || [],
     "boothCode":this.filterform.value.booth ? this.filterform.value.booth : []
  }
   this.masterService.dashboardList(data).subscribe(
    response => {
      this.loaderVisbility  = false;
      this.appliedFiltersForm = []

      if(this.filterform.value.wardList && this.filterform.value.wardList.length > 0) {
        this.appliedFiltersForm.push({
          key : "Party Ward List",
          value : this.filterform.value.wardList
        });
      }
      if(this.filterform.value.booth && this.filterform.value.booth.length > 0) {
        this.appliedFiltersForm.push({
          key : "Booth",
          value : this.filterform.value.booth.map(el => "Booth - "+el)
        });
      }
      if(response['body']['status'] == APICODE.SUCCESS_CODE)
      {
      //gender data binding
        this.genderDataBind = response['body']['gender_data'] ? response['body']['gender_data']: "0";
        let series = [Number(this.genderDataBind[0].male_count), Number(this.genderDataBind[0].female_count), Number(this.genderDataBind[0].transgender_count)]
        this.genderTotalVoter = Number(this.genderDataBind[0].total_voter).toLocaleString('en-IN');
        this.genderBind()
        this.GenderDetails.series=series;
      //voter status data binding
        this.voterStatusDetails = response['body']['voter_status_data'] ? response['body']['voter_status_data']: "0";
        this.totalVoter = Number(this.voterStatusDetails[0].total_voter).toLocaleString('en-IN')
        this.totalFamily = Number(this.voterStatusDetails[0].total_family).toLocaleString('en-IN')
        let voterSeries = [Number(this.voterStatusDetails[0].total_verified), Number(this.voterStatusDetails[0].total_pending), Number(this.voterStatusDetails[0].total_inprogress)];
        this.voterStatusBind();
        this.voterDetails.series=voterSeries;
      //religion status binding
        this.religionStatusDetails = response['body']['religion_data'] ? response['body']['religion_data']: "0";
        let religionseries = [Number(this.religionStatusDetails[0].hindu_count), Number(this.religionStatusDetails[0].christan_count), Number(this.religionStatusDetails[0].musilm_count),Number(this.religionStatusDetails[0].others_count)]
        this.religionTotalVoter =  Number(this.religionStatusDetails[0].total_voter).toLocaleString('en-IN');
        this.religionBind();
        this.religionOptions.series=religionseries;
      //favour party binding
        this.favourStatusDetails = response['body']['party_favour_data'] ? response['body']['party_favour_data']: "0";
        this.partyDataBinding(this.favourStatusDetails);
        this.favourPartyBind()
        this.favourPartyDetails.series[0].data= this.partyCounts;
      //age binding
        this.ageRangeDetails = response['body']['age_data'] ? response['body']['age_data']: "0";
        this.fnAgeDataBinding(this.ageRangeDetails)
        this.ageDataBinding()
        this.AgeDetails.series[0].data = this.ageRangeWise;
      
      //party membership
        this.partyMembershipArray = response['body']['willigness_data'] ? response['body']['willigness_data']: "0";
        this.partyMemberDetailBinding(this.partyMembershipArray);
        this.partyMemberBinding();
        this.partyMemberDetails.series= this.partyDetailsRange;
      }
      else if(response['body']['status'] == APICODE.UNAUTHORIZED_CODE)
      {
          this.loginService.generateRefreshToken().subscribe(tokenResponse => {
            if(tokenResponse['body']['status'] == APICODE.SUCCESS_CODE)
            {
        
              var accessToken = tokenResponse['body']?tokenResponse['body']['accessToken']?tokenResponse['body']['accessToken']:"":"";
              var refreshToken = tokenResponse['body']?tokenResponse['body']['refreshToken']?tokenResponse['body']['refreshToken']:"":"";            
              localStorage.setItem(localStorageKeys.ACCESS_TOKEN, accessToken);
              localStorage.setItem(localStorageKeys.REFRESH_TOKEN, refreshToken);
              console.log("generateRefreshToken");
              this.fnBindDashboardData();                
    
            }
            else
          {
            this.loaderVisbility = false;
          }
          },error =>{

          })
      }
      else
      {
         this.loaderVisbility  = false;
      }
     
    }, error => {
      this.loaderVisbility  = false;
      
    });
  }
  genderBind(){
    this.GenderDetails = {
      series: [],
      labels: ["Male", "Female", "Transgender"],
      chart: {
        width: 300,
        height: 400,
        type: "donut"
      },
      dataLabels: { // add this part to remove %
        enabled: true,
        formatter: function(val) {
          val = Number(val).toFixed(1);
          return val + "%";
        },
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 90,
          offsetY: 10
        }
      },
      grid: {
        padding: {
          bottom: -80
        }
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }
      ],
      legend: {
        position: 'bottom',
        formatter: function (val, opts) {
          var gen = opts.w.config.series[opts.seriesIndex];
          gen = Number(gen).toLocaleString('en-IN')
          return `${val} (${gen})`;
        }
    },
    color: ['#FF4560', '#008FFB', '#00E396'], 
    };
  }
  voterStatusBind(){
    this.voterDetails = {
      series: [],
      labels: ["Verified", "Pending", "In-progress"],
      chart: {
        width: 300,
        height: 400,
        type: "donut"
      },
      dataLabels: { // add this part to remove %
        enabled: true,
        formatter: function(val) {
          val = Number(val).toFixed(1);
          return val + "%";
        },
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 90,
          offsetY: 10,
        }
      },
      grid: {
        padding: {
          bottom: -80
        }
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }
      ],
      legend: {
        position: 'bottom',
        formatter: function (val, opts) {
          var value = opts.w.config.series[opts.seriesIndex];
          value = Number(value).toLocaleString('en-IN')
          return `${val} (${value})`;
        }
  
    }
    };
  }
  religionBind(){
    this.religionOptions = {
      series: [],
      labels: ["Hindu", "Chrisitian", "Muslim","Others"],
      chart: {
        width: 300,
        height: 400,
        type: "donut"
      },
      dataLabels: { // add this part to remove %
        enabled: true,
        formatter: function(val) {
          val = Number(val).toFixed(1);
          return val + "%";
        },
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 90,
          offsetY: 10
        }
      },
      grid: {
        padding: {
          bottom: -80
        }
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }
      ],
      legend: {
        position: 'bottom',
        formatter: function (val, opts) {
          var rel = opts.w.config.series[opts.seriesIndex];
          rel = Number(rel).toLocaleString('en-IN')
          return `${val} (${rel})`;
        }
    }
    };
  }
  favourPartyBind(){
    this.favourPartyDetails = {
      series: [
        {
          name: "",
          data: []
        }
      ],
      chart: {
        height: 250,
        type: "bar",
        toolbar: {
          show: false // Hide the toolbar including the download button
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top" // top, center, bottom
          }
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function(val) {         
          val = val.toLocaleString('en-IN')
          return val + "";
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"]
        }
      },

      xaxis: {
        categories: this.partyNameData,
        position: "top",
        labels: {
          offsetY: -18
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: "solid",
            gradient: {
              colorFrom: "#27DA78",
              colorTo: "#50C878",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5
            }
          }
        },
        tooltip: {
          enabled: true,
          offsetY: -35
        }
      },
      fill: {
        type: "solid",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [50, 0, 100, 100]
        },
        colors:['#90CAF9']
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: false,
          formatter: function(val) {
            return val + "";
          }
        }
      },
      title: {
        text: "Monthly Inflation in Argentina, 2002",
        floating: false,
        offsetY: 320,
        align: "center",
        style: {
          color: "#444"
        }
      }
    };
  }
  partyDataBinding(value:any){
    this.partyNameData=[];
    this.partyCountData = [];
    if(value.length == 0){
      this.partyNoRecordFound = true;
      return;
    }
    let zeroRecords:any;
    for(let i=0;i<value.length;i++){
      this.partyNameData.push(value[i].party_name);
      this.partyCountData.push(Number(value[i].total_voter));
    }
      zeroRecords=value.filter(data=>data.total_voter == "0")
    if(zeroRecords.length == this.partyCountData.length){
      this.partyNoRecordFound = true;
    }
    else{
      this.partyNoRecordFound = false;
    }
    this.partyCounts = this.partyCountData;
  }
  ageDataBinding(){
    this.AgeDetails = {
      series: [
        {
          name: "",
          data: []
        }
      ],
      chart: {
        height: 250,
        type: "bar",
        toolbar: {
          show: false // Hide the toolbar including the download button
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top" // top, center, bottom
          }
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function(val) {
          val = val.toLocaleString('en-IN')
          return val + "";
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"]
        }
      },
     
      xaxis: {
        categories:this.dyanimcAgeRange,
        position: "top",
        labels: {
          offsetY: -18
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: "solid",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5
            }
          }
        },
        tooltip: {
          enabled: true,
          offsetY: -35
        }
      },
      fill: {
        type: "solid",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [50, 0, 100, 100]
        },
        colors:['#C5E1A5']
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: false,
          formatter: function(val) {
            return val + "";
          }
        }
      },
      title: {
        text: "Monthly Inflation in Argentina, 2002",
        floating: false,
        offsetY: 320,
        align: "center",
        style: {
          color: "#444"
        }
      }
    };
  }
  fnAgeDataBinding(data:any){
    if(data.length < 0){
      return;
    }
    this.ageRangeWise=[];
    this.dyanimcAgeRange=[];
    for(let i=0;i<data.length;i++){
      this.ageRangeWise.push(Number(data[i].total_voter));
      this.dyanimcAgeRange.push((data[i].age))
    }
  }
  partyMemberBinding(){
    this.partyMemberDetails = {
      series: [],
      chart: {
        width: 380,
        type: "pie"
      },
      labels: ["Member with Card","Member without Card","Willing and Applied","Willing but Not Applied","Not a Party Member"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom',
              align:'start'
            }
          }
        }
      ],
      legend:{
        position: 'bottom',
        formatter: function (val, opts) {
          var partyMem = opts.w.config.series[opts.seriesIndex];
          partyMem = Number(partyMem).toLocaleString('en-IN')
          return `${val} (${partyMem})`;
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function(val) {
          val = Number(val).toFixed(1);
          return val + "%";
        },
      },
    };
  }
  partyMemberDetailBinding(member:any){
    let filterArray=[];
    let totalDataArray=[];
    let zeroPartyMember:any;
    if(member.length < 0){
      this.partyMemberNoRecord = true;
      return;
    }
    this.partyDetailsRange=[];
    filterArray = member.filter((val=>val.typename != 'Total verified voter'))
    totalDataArray = member.filter((val=>val.typename == 'Total verified voter'))
    this.partyTotalVoter = Number(totalDataArray[0].total).toLocaleString('en-IN');
    for(let i=0;i<filterArray.length;i++){
      this.partyDetailsRange.push(Number(filterArray[i].total));
    }
    zeroPartyMember=filterArray.filter(data=>data.total == "0")
    if(zeroPartyMember.length == (this.partyDetailsRange.length)){
      this.partyMemberNoRecord = true;
      return;
    }
    else{
      this.partyMemberNoRecord = false;
      return;
    }
    
  }
}
