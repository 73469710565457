import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { RouterPath } from 'src/app/utils/enums/route.enum';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if (JSON.parse(localStorage.getItem('IS_LOGGED_IN')) && String(localStorage.getItem('ng2Idle.main.expiry')) != "" && localStorage.getItem('ng2Idle.main.expiry')) {
      var timeout = Number(localStorage.getItem('ng2Idle.main.expiry'));
      if (new Date().getTime() > timeout) {
          sessionStorage.clear();
          localStorage.clear();
          this.router.navigate([RouterPath.LOGIN]);

          return false;
      }
    }
    return true
  }
}
