import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { LayoutService } from '../../@vex/services/layout.service';
import { filter, map, startWith } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { checkRouterChildsData } from '../../@vex/utils/check-router-childs-data';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ConfigService } from '../../@vex/config/config.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SidebarComponent } from '../../@vex/components/sidebar/sidebar.component';
import { Idle, DEFAULT_INTERRUPTSOURCES } from "@ng-idle/core";
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginService } from '../services/login.service';

@UntilDestroy()
@Component({
  selector: 'vex-custom-layout',
  templateUrl: './custom-layout.component.html',
  styleUrls: ['./custom-layout.component.scss']
})
export class CustomLayoutComponent implements OnInit {

  sidenavCollapsed$ = this.layoutService.sidenavCollapsed$;
  isFooterVisible$ = this.configService.config$.pipe(map(config => config.footer.visible));
  isDesktop$ = this.layoutService.isDesktop$;
  timedOut = false;

  toolbarShadowEnabled$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    startWith(null),
    map(() => checkRouterChildsData(this.router.routerState.root.snapshot, data => data.toolbarShadowEnabled))
  );

  @ViewChild('configpanel', { static: true }) configpanel: SidebarComponent;

  constructor(private layoutService: LayoutService,
              private configService: ConfigService,
              private breakpointObserver: BreakpointObserver,
              private router: Router,
              private loginService: LoginService,
              private matDialog: MatDialog, 
              private idle: Idle,
              private snackBar: MatSnackBar) { }

  ngOnInit() {
    // sets an idle timeout of 5 seconds, for testing purposes.
    this.idle.setIdle(1800);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(5);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    // this.idle.onIdleEnd.subscribe(() => (this.idleState = "No longer idle."));
    this.idle.onTimeout.subscribe(() => {
      // this.idleState = "Timed out!";
      if (this.matDialog.openDialogs)
        this.matDialog.closeAll();
        this.snackBar.open("Your session has expired", "", {
          duration: 5000, verticalPosition: 'top', horizontalPosition: 'right', panelClass: ['custom-snackbar-danger']
        });
        this.timedOut = true;
        setTimeout(() => {
          this.loginService.Logout();
        },4000);
      });
    this.layoutService.configpanelOpen$.pipe(
      untilDestroyed(this)
    ).subscribe(open => open ? this.configpanel.open() : this.configpanel.close());

    }

  @HostListener('document:mousedown', ['$event'])
  onMouseUpEvents(event) {
    this.reset();
  }

  @HostListener("wheel", ["$event"])
  public onScroll(event: WheelEvent) {
    this.reset();
  }

  @HostListener('document:keydown', ['$event']) 
  onKeydownHandler(event: KeyboardEvent) {
    this.reset();
  }

  @HostListener('mousemove', ['$event'])
  onMousemove(event: MouseEvent) { 
    this.reset();
  }
  reset() {         //Session time out reset function
    this.idle.watch();
    // this.idleState = "Started.";
    this.timedOut = false;
  }
}
