<vex-page-layout>
    <div class="card_bgcolor Card_pad_left">
      <div class="filter_flex">  
          <div class="parent pad-chips chips pad-bt"  style="width:95%">
            <div *ngFor="let chip of appliedFiltersForm" class="parent br-rad ht-34">
              <span class="chip-key">{{chip.key}}</span>
              <span *ngFor="let chipVal of chip.value" class="filter-cl filter-chips pad-top">{{chipVal}}</span>
            </div>
          </div>
        <button style="width:5%" class="config-panel-toggle filter_alignment filter_btn filter_top" color="primary" mat-fab type="button"  matTooltip="Filter" (click)="openFilterPop($event)">
        Filter
      </button></div>
      <div fxLayout="column" class="card_top">
        <div fxLayout="row" fxFlex="100%" fxLayout.sm="column"  fxLayout.xs="column" fxLayout.md="column">
          <mat-card class="margin_top"  fxFlex="50%">
            <div fxLayout="column" fxFlex="100%" fxLayout.sm="column" fxLayout.xs="column" fxLayout.md="column" class="margin-card-bottom">
                    <mat-card-header fxFlex="50%">
                      <div fxLayout="column" fxFlex="100%"  class="voter_boreder" [class.mat-elevation-z2]="!isActive" [class.mat-elevation-z8]="isActive">
                        <div fxLayout="row" fxLayoutGap="28%">
                            <div class="voter_detail_tile"> Voters Verification Status</div>
                            <div>
                              <div> <span class="voter_details_font">Total</span>   : <span class="voter_detail_font_weight">{{totalVoter}}</span></div>
                              <!-- <div> <span class="voter_details_font total_fam_mr_lft">Total Families</span> : <span  class="voter_detail_font_weight">{{totalFamily}}</span></div>       -->
                            </div>
                          
                        </div>
                        <div id="votersDetailschart" class="voter_top">
                            <apx-chart class="apex_class"
                            width=380  
                              [series]="voterDetails.series"
                              [chart]="voterDetails.chart"
                              [labels]="voterDetails.labels"
                              [legend]="voterDetails.legend"
                              [grid]="voterDetails.grid"
                              [colors]="voterColors"
                              [dataLabels]="voterDetails.dataLabels"
                              [plotOptions]="voterDetails.plotOptions"
                              [responsive]="voterDetails.responsive"
                            ></apx-chart>
                          </div>
                      </div>
                    </mat-card-header>
                    <mat-card-header fxFlex="50%">
                      <div fxLayout="column" fxFlex="100%" class="voter_boreder" [class.mat-elevation-z2]="!isActive" [class.mat-elevation-z8]="isActive">
                        <div fxLayout="row" fxLayoutGap="60%">
                            <div class="voter_detail_tile"> Religion</div>
                            <div>
                              <div> <span class="voter_details_font">Total</span> : <span class="voter_detail_font_weight">{{religionTotalVoter}}</span></div>   
                            </div>
                        </div>
                        <div id="totalCount" class="regligion_style chart_alignemnt">
                          <apx-chart class="religion_class"
                            [series]="religionOptions.series"
                            [chart]="religionOptions.chart"
                            [labels]="religionOptions.labels"
                            [legend]="religionOptions.legend"
                            [grid]="religionOptions.grid"
                            [colors]="religionColors"
                            [dataLabels]="religionOptions.dataLabels"
                            [plotOptions]="religionOptions.plotOptions"
                            [responsive]="religionOptions.responsive"
                          ></apx-chart>
                        </div>
                      </div>
                    </mat-card-header>
            </div>
            </mat-card>
            <mat-card class="margin_top" fxFlex="50%">
              <div fxLayout="column" fxFlex="100%" fxLayout.sm="column" fxLayout.xs="column" fxLayout.md="column" class="margin-card-bottom">
                <mat-card-header fxFlex="50%">
                  <div fxLayout="column" fxFlex="100%" class="voter_boreder" [class.mat-elevation-z2]="!isActive" [class.mat-elevation-z8]="isActive">
                    <div fxLayout="row" fxLayoutGap="54%">
                        <div class="voter_detail_tile"> Gender</div>
                        <div>
                          <div> <span class="voter_details_font">Total</span> : <span class="voter_detail_font_weight">{{genderTotalVoter}}</span></div>   
                        </div>
                    </div>
                    <div id="GenderCount" class="gender_top chart_alignemnt">
                      <apx-chart class="apex_class"
                        [series]="GenderDetails.series"
                        [chart]="GenderDetails.chart"
                        [colors]="genderColors"
                        [dataLabels]="GenderDetails.dataLabels"
                        [labels]="GenderDetails.labels"
                        [legend]="GenderDetails.legend"
                        [grid]="GenderDetails.grid"
                        [plotOptions]="GenderDetails.plotOptions"
                        [responsive]="GenderDetails.responsive"
                      ></apx-chart>
                    </div>
                  </div>
                </mat-card-header>
                <mat-card-header fxFlex="50%">
                  <div fxLayout="column" fxFlex="100%" class="voter_boreder" [class.mat-elevation-z2]="!isActive" [class.mat-elevation-z8]="isActive">
                    <div fxLayout="row" fxLayoutGap="44%">
                        <div class="voter_detail_tile"> Party Membership</div>
                        <div>
                          <div> <span class="voter_details_font">Total</span> : <span class="voter_detail_font_weight">{{partyTotalVoter}}</span></div>   
                        </div>
                    </div>
                    <div id="partyMemberCount" class="party_details_style" fxFlex="100%" *ngIf="!partyMemberNoRecord">
                      <apx-chart fxFlex="100%"  class="chart_alignemnt"
                        [series]="partyMemberDetails.series"
                        [chart]="partyMemberDetails.chart"
                        [dataLabels]="partyMemberDetails.dataLabels"
                        [labels]="partyMemberDetails.labels"
                        [colors]="partyMemberColor"
                        [legend]="partyMemberDetails.legend"
                        [responsive]="partyMemberDetails.responsive"
                      ></apx-chart>
                    </div>
                    <div *ngIf="partyMemberNoRecord" class="recordfound_data">
                      No Record Found
                    </div>
                  </div>
                </mat-card-header>
        </div>
              </mat-card>
        </div>
        <div fxLayout="row" fxFlex="100%">
          <mat-card  class="margin_top age_mrg_bottom" fxFlex="100%">
              <mat-card-header fxFlex="100%" >
                   
                <div fxLayout="column" fxFlex="100%" class="voter_boreder" [class.mat-elevation-z2]="!isActive" [class.mat-elevation-z8]="isActive">
                  <div fxLayout="row" fxLayoutGap="30%">
                      <div class="voter_detail_tile"> Age Group</div>
                      <div>
                      </div>
                    
                  </div>
                  <div id="AgeCount" class="voter_top">
                    <apx-chart
                    [series]="AgeDetails.series"
                    [chart]="AgeDetails.chart"
                    [dataLabels]="AgeDetails.dataLabels"
                    [plotOptions]="AgeDetails.plotOptions"
                    [yaxis]="AgeDetails.yaxis"
                    [xaxis]="AgeDetails.xaxis"
                    [fill]="AgeDetails.fill"
                    [title]="AgeDetails.title"
                  ></apx-chart>
                    </div>
                </div>
            </mat-card-header>
          </mat-card>
        </div>
        <div fxLayout="row" fxFlex="100%">
          <mat-card  class="margin_top mrg_bottom35" fxFlex="100%">
            <div fxFlex="100%" fxLayout="column" class="border_clr">
              <mat-card-header fxFlex="100%" >
                <div fxLayout="column" fxFlex="100%" class="voter_boreder" [class.mat-elevation-z2]="!isActive" [class.mat-elevation-z8]="isActive">
                      <div class="voter_detail_tile">  Favour Party</div>
                  <div id="favourPartyCount" class="voter_top" *ngIf="!partyNoRecordFound">
                    <apx-chart
                    [series]="favourPartyDetails.series"
                    [chart]="favourPartyDetails.chart"
                    [dataLabels]="favourPartyDetails.dataLabels"
                    [plotOptions]="favourPartyDetails.plotOptions"
                    [yaxis]="favourPartyDetails.yaxis"
                    [xaxis]="favourPartyDetails.xaxis"
                    [fill]="favourPartyDetails.fill"
                    [title]="favourPartyDetails.title"
                  ></apx-chart>
                    </div>
                  <div *ngIf="partyNoRecordFound" class="favour_party_no_record">
                    No Record Found
                  </div>
                </div>
              </mat-card-header>
             </div>
          </mat-card>
          </div>
      </div>
      
    </div>
    <div *ngIf="loaderVisbility==true" class="overlay">
      <div class="center">
          <mat-progress-spinner diameter=50 
                              mode="indeterminate" 
                    color="accent">
        </mat-progress-spinner> 
      </div>
      </div>
</vex-page-layout>


<vex-sidebar #configpanel [invisibleBackdrop]="true" position="right">
  <vex-constituency-filter style="width: 100%;" [boothVisible]="boothVisible" (IdDetails)="dashboardFilter($event)"  (closeConfig)="closeFilterPop($event)"
  ></vex-constituency-filter>   
</vex-sidebar>