import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalConstants } from '../Common/global-constants';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { apiConfigUrls } from '../Common/api-config-urls';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  baseUrl=GlobalConstants.ApibaseUrl;
  liveurl= "";
  login: any;
  refreshToken:any=""
  userInfo :any=""
  userName:any=""
  constructor(private http: HttpClient, private router: Router) { }

  loginUser(data:any,url:string)      
  {
    this.liveurl = this.baseUrl + url;  
    return this.http.post(this.liveurl,data);
  }

  generateRefreshToken()              
  {
    this.refreshToken = localStorage.getItem('REFRESH_TOKEN')?localStorage.getItem('REFRESH_TOKEN'):"";
    this.userInfo = localStorage.getItem('USER_INFO')?JSON.parse(localStorage.getItem('USER_INFO')):"";
    if(this.userInfo != "")
    {
      this.userName = this.userInfo['user_name']?this.userInfo['user_name']:""
    }
    var data =   {
      "token":this.refreshToken,
      "user":this.userName
    }
    this.liveurl=this.baseUrl+apiConfigUrls.refreshToken;  
    return this.http.post(this.liveurl,data);
  }

  Logout()      //logout functionality
  {
    sessionStorage.clear();
    localStorage.clear();
    this.router.navigateByUrl('/login');
  }
}
