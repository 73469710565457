<div class="w-full flex flex-col sm:flex-row justify-between mrg_bottom" fxFlex="100%" fxLayout="column">
  <div class="container p-gutter grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-4 padd_container" fxFlex="100%" fxLayout="column">
    <h2 class="headline mb-4 filt_tit"  fxFlex="100%" fxLayoutAlign="space-between center" >
      <div>
      <mat-icon svgIcon="mat:filter_list" class="filt-icon"></mat-icon><span class="mrg-lf">Filter Options</span>
    </div>
      <mat-icon svgIcon="mat:cancel" class="cancel-filt" (click)="closeConfigure()"></mat-icon>
    </h2>


    <div class="container" fxFlex="92%" fxLayout="column">
      <form [formGroup]="filterform" fxFlex="100%" fxLayoutGap="30px">
        <div class="container p-gutter grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 padding" fxLayout="column" fxFlex="92%" >
          <!-- <mat-form-field class="fw-400 fs-10 ht-34 wt-180 cl-black pad-rig matSelectpad ht10" appearance="outline"
            autocomplete="off" fxFlex="19%">
            <mat-label class="mat-client-label">State</mat-label>
            <input type="text" placeholder="State" aria-label="Number" formControlName="statename" matInput
              [matAutocomplete]="auto" #autoCompleteInput (focus)="loadstate()">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
              (optionSelected)="changeState($event.option.value)">
              <mat-option *ngFor="let client of stateObserb | async" class="fs-10 ht-30"
                [value]="client.state_name">{{client.state_name}}
              </mat-option>
            </mat-autocomplete>
            <mat-icon matSuffix class="select-arrow-icon">
              <img class="mrg-8" src="./assets/img/arrow.png"></mat-icon>
          </mat-form-field> -->
          <div class="container p-gutter grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 padding overflow_style" fxLayout="column" fxFlex="100%" fxLayoutGap="18px">
          <mat-form-field class="fw-400 fs-10 ht-34 wt-180 cl-black pad-rig matSelectpad mat_constituency_top" appearance="outline"
            autocomplete="off" fxFlex="100%">
            <mat-label class="mat-client-label">Constituency</mat-label>
            <input type="text" placeholder="Constituency" aria-label="Number" formControlName="constituency" matInput
              [matAutocomplete]="autos" #autoCompleteInput (focus)="loadconstituency()">
            <mat-autocomplete autoActiveFirstOption #autos="matAutocomplete"
              (optionSelected)="changeconstituency($event.option.value)">
              <mat-option *ngFor="let constituency of constituencyObserb | async" class="fs-10 ht-30"
                [value]="constituency.constituency_name">{{constituency.constituency_name}}
              </mat-option>
            </mat-autocomplete>
            <mat-icon matSuffix class="select-arrow-icon">
              <img class="mrg-8" src="./assets/img/arrow.png"></mat-icon>
          </mat-form-field>

          <!-- Zone filter -->
          <mat-form-field class="fw-400 fs-10 ht-34 wt-180 cl-black pad-rig matSelectpad" appearance="outline"
            autocomplete="off" fxFlex="100%" [ngClass]="filteralignment ? 'mrg_bottom':''">
            <mat-label class="mat-client-label">Zone</mat-label>
            <mat-select (valueChange)="changeZoneValue(choosedvalue)" #choosedvalue formControlName="zoneSelect" >
              <mat-option *ngFor="let zones of zoneList" class="fs-10 ht-30"
              [value]="zones.zone_name">{{zones.zone_name}}
            </mat-option>
            </mat-select>
            <!-- <mat-icon matSuffix class="select-arrow-icon">
              <img class="mrg-8" src="./assets/img/arrow.png"></mat-icon> -->
            <!-- <input type="text" placeholder="Zone" aria-label="Number" formControlName="zoneCode" matInput
              [matAutocomplete]="zoneauto" #autoCompleteInput (focus)="loadZone()">
            <mat-autocomplete autoActiveFirstOption #zoneauto="matAutocomplete"
              (optionSelected)="changeZoneValue($event.option.value)">
              
            </mat-autocomplete>
             -->
          </mat-form-field>          

          <!-- ward chip -->
          <mat-form-field class="fw-400 fs-10 ht-34 wt-180 cl-black pad-rig matSelectpad" appearance="outline"
            autocomplete="off" fxFlex="100%"  *ngIf="wardVisible" [ngClass]="filteralignment ? 'mrg_bottom':''">
            <mat-label>Party Ward</mat-label>
            <mat-chip-grid #chipGrid aria-label="Party Ward">
              <mat-chip-row *ngFor="let ward of wards" (removed)="removeWard(ward)">
                <div class="category-chip__text wt-auto"> {{ward}} </div>
                <button matChipRemove [attr.aria-label]="'remove ' + ward">
                  <mat-icon svgIcon="mat:cancel"></mat-icon>
                </button>
              </mat-chip-row>
            </mat-chip-grid>
            <input placeholder="Party Ward" #wardInput [formControl]="wardCtrl" [matChipInputFor]="chipGrid"
              [matAutocomplete]="wardbooth" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (matChipInputTokenEnd)="addWard($event)" />
            <mat-autocomplete #wardbooth="matAutocomplete" (optionSelected)="selectedWard($event)">
              <mat-option *ngFor="let ward of filteredward | async" [value]="ward">
                {{ward.ward_name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
       
          <mat-form-field class="fw-400 fs-10 ht-34 wt-180 cl-black pad-rig matSelectpad" appearance="outline" fxFlex="100%"
            autocomplete="off" *ngIf="boothVisible" [ngClass]="filteralignment ? 'mrg_bottom':''">
            <mat-label>Booth No.</mat-label>
            <mat-chip-grid #chipGrid aria-label="Booth No.">
              <mat-chip-row *ngFor="let booth of booths" (removed)="remove(booth)">
                <div class="category-chip__text"> Booth - {{booth}} </div>
                <button matChipRemove [attr.aria-label]="'remove ' + booth">
                  <mat-icon svgIcon="mat:cancel"></mat-icon>
                </button>
              </mat-chip-row>
            </mat-chip-grid>
            <input placeholder="Booth No." #boothInput [formControl]="boothCtrl" [matChipInputFor]="chipGrid"
              [matAutocomplete]="autobooth" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (matChipInputTokenEnd)="add($event)" />
            <mat-autocomplete #autobooth="matAutocomplete" (optionSelected)="selected($event)">
              <mat-option *ngFor="let booth of filteredbooths | async" [value]="booth">
                Booth - {{booth.bth_booth_code}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <vex-voters-filter (SelectedFilters)="votersSubFilter($event)" *ngIf="AdditionalFilter" #votersFilter
            [totalGenderList]="genderList"></vex-voters-filter>
          </div>
            <div fxLayout="row" fxFlex="10%" fxLayoutAlign="space-between center" >
              <button mat-raised-button  fxFlex="45%" class="clear_fil reset_btn_color" (click)="resetFilter()">RESET</button>
              <button mat-raised-button color="warn" fxFlex="45%" (click)="filterEmit()">APPLY</button>
            </div>
        </div>
      </form>
    </div>
  </div>
</div>